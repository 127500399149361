.__export {
  width: 100%;
  height: auto;
  .__export_wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 300px;
    h1 {

          font-size: 50px !important;
    color: white !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    }
  }
  .__export_container {
    padding: 50px 40px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 50px;
    .__exp_con {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: black;
      }
    }
    .__exp_con_num {
      border-radius: 30px;
      padding: 40px;
      background-color: rgb(249, 249, 249);
      align-items: center;
      justify-content: center;
      width: 100%;
      display: flex;
      gap: 50px;
      .__exp_con_in {
        .__exp_in {
          h1 {
            font-size: 100px;
            line-height: 1;
          }
          span {
            font-size: 32px;
            text-transform: uppercase;
            font-weight: 800;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .__export_container {
      grid-template-columns: 1fr;
    }
    .__exp_con_num {
      flex-direction: column;
    }
  }
  .__exp_map {
    padding: 5px 40px;
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
}

.__exp_round_con {
  width: 100%;
  border-radius: 30px !important;
  background-color: rgb(249, 249, 249);
  .__exp__wrap {
    display: grid;
    //padding: 50px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px !important;
    .__exp__round {
      padding:20px ;
      width: 100%;
      height: auto;
      grid-gap: 20px;
      display: grid;
      grid-template-columns: 1fr !important;
      .__exp__r_in {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        width: 100%;
        img {
          width: 100px;
        }
        .__exp_r_in_text {
          align-items: center;
          justify-content: center;
          width: 100%;
          display: flex;
          padding: 40px;
          text-align: center;
          h2 {
            font-weight: 400;
            line-height: 1.2;
            color: gray;
          }
        }
      }
    }
    .__exp__form__con {
      align-items: center;
      display: flex;
      align-items: center;
      text-align: left !important;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 30px;
      
      input,textarea {
        outline: none;
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);
        

        .placeholder {
          font-size: 12px;
          color: rgb(238, 238, 238) !important;
        }
      }
      input::placeholder{
        color: rgb(160, 160, 160);
        font-size: 14px;
      }
      .con_input {
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);
        .__country__select{
          width: 100%;
          background: none;
          border: none;
          outline: none;
        }
        
      }

      .input_con {
        width: 100%;
      }
      .__exp__form {
        width: 100%;
        background-color: white !important ;
        padding: 30px;
        form {
          width: 100%;
          height: auto;

          .from_sec {
            gap: 30px;
            padding: 10px 0px;
            .from_sec_in {
              display: flex;
              gap: 20px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .__exp__wrap {
      grid-template-columns: 1fr;
    }
  }
}
