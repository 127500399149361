.contact_info_wrap {
  text-align: left !important;
  padding: 60px;
  width: 100%;
  height: auto;
  .con_data {
    padding-bottom: 20px;
  }

  h1 {
    font-size: 3rem;
    padding-bottom: 20px;

  }
  .contact_info {
    text-align: left !important;
    padding: 10px 30px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
}
.__contact_form {
  text-align: left;
  padding: 20px;
  width: 100%;
  height: auto;
  border-radius: 10px !important;
  background-color: #f8f7fa;
  .redio_con {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .con_sel {
    padding: 10px 20px;
    margin: 10px 0px;
    width: 100%;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid #f0efef;
    background-color: white !important;
  }
  input,select {
    margin: 10px 0px;
    padding: 10px 20px;
    border-radius: 10px !important;
    border: 1px solid #f0efef;
    background-color: white !important;
  }
  // @media screen and (max-width:900px) {
  //    input,select{
  //     // width: 100% !important;
  //    }
    
  // }
  .__form_in {
    display: grid;
    grid-template-columns: 1fr;
  }
  textarea {
    font-family: Helvetica, sans-serif;

    width: 100%;
    height: 100px;
    padding: 10px 10px;
    border-radius: 10px !important;
    border: 1px solid #f0efef;
    background-color: white !important;
  }
  button {
    border: 1px solid #f0efef;
    padding: 10px 30px;
    border-radius: 30px;
    background-color: orange;
    color: rgb(0, 0, 0) !important;
  }
  button:hover {
    transition: 0.2s ergb(255, 255, 255)-out;
    background-color: rgb(235, 235, 234);
    color: black !important;
  }
}
.con_input {
  background-color: rgba(255, 255, 255, 0) !important;
}
ul{
  li{
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      margin: 0;
      padding: 0;
    }
  }
}
.radio_sec{
  padding: 0px 20px;
  width: 100%;
display: flex;
justify-content: space-between;
}
form{
  gap: 20px;

}


.__popUp {

  height: 100vh;
  width: 100vw;
  z-index: 99;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(219, 219, 219, 0.8);
  top: 0;
  left: 0;
  display: flex;
  .close__ {
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    span{
      padding: 10px 40px;
      background-color: orange;
      color: white;
    }
    span:hover{
      background-color: rgba(255, 166, 0, 0.562);
      transition: all .2s ease-in-out;
    }
  }
  .popEdit__{
    align-items: center;
    justify-content: center;
    max-width: 400px !important;
      margin: 20px;
    padding: 30px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    .__popuInfo {
      width: 100%;
      height: auto;
       align-items: center!important; 
          justify-content: center !important;
      gap: 20px;
      display: flex;
      flex-direction: column;
      .__oky{
          align-items: center!important; 
          justify-content: center !important;
          width: 150px;
          height: 150px;
          background-color: rgb(246, 246, 246);
          overflow: hidden;
          border-radius: 50%;
          object-fit: contain;
          display: flex;
          img{
            display: flex;
            width: 100%;
          }
        }
      .popup_tx{
        justify-content: center !important;
        align-content: center !important;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        
        h2{
          color:orange !important;
        }
      }
      
    }
  }
}
