.heroBanner {
  width: 100vw;
  height: 60vh;
  overflow: hidden;
  position: relative;

  // .css-selector {
  //   background: linear-gradient(270deg, #f0af0b94, #0bc6f090,#f0af0b00);
  //   background-size: 600% 600%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 60vh;
  //   width: 100vw;
  //    align-items: center;
  //   justify-content: end;
  //   display: flex;
  //   flex-direction: column;
  //   -webkit-animation: AnimationName 26s ease infinite;
  //   -moz-animation: AnimationName 26s ease infinite;
  //   animation: AnimationName 26s ease infinite;
  // }
   h1 {
     padding-bottom: 20px;
      font-size: 30px !important;
      color: white !important;
      font-weight: 400 !important;
    }

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .__wall_wrap {

    align-items: center;
    justify-content: end;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 300px;
    padding: 40px 100px;
   
  }
  video {
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .heroBanner {
    height: auto;
  }
}
