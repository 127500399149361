.__home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .ex_banner{
    width: 100%;
    height:auto;
    img{
      width: 100%;
    }
  }

  .__heading {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: auto;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
      color: #fcb315;
      line-height: 1.2;
      font-size: 30px !important;
      span {
        color: black;
        font-weight: 600;
      }
    }
  }
  .__ceo {
    padding: 50px 40px;
    width: 100%;
    height: auto;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 30px;
    .__video {
      width: 100%;
      border-radius: 100px 0px 100px 0px;
      overflow: hidden;
      height: 550px;
      .__player {
        overflow: hidden;
        height: 550px;
      }
    }
    .__ceoText_wrap {
      margin-left: -100px;
      border-radius: 100px 0px 100px 0px;
      padding: 40px;
      background: rgb(56, 56, 56);
      border-left: 4px solid #fcb315;
      border-bottom: 4px solid #fcb315;
      background: linear-gradient(
        45deg,
        rgb(82, 82, 82) 0%,
        rgb(70, 70, 70) 24%,
        rgb(0, 0, 0) 100%
      );
      .__ceoText {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        height: auto;
        img {
          width: 130px;
          padding-bottom: 20px;
        }
        p {
          color: white !important;
          padding-bottom: 20px;
        }
        span {
          color: #fcb315;
          font-size: 20px;
        }
        small {
          color: white;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .__ceoText_wrap {
        margin-left: 0px;
      }
    }
  }
  .__global {
    background-color: rgb(249, 249, 249);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    .__container {
      .__global_text {
        align-items: center;
        justify-content: center;
        display: grid;
        padding: 60px 40px 0px 40px ;
        grid-template-columns: 2fr 1fr 4fr;
        width: 100%;
    
        .__global_head {
          padding-right: 50px;
          span {
            color: black;
          }
          .__global_sub_text {
            padding-left: 100px;
          }
        }
      }
      @media screen and (max-width:1000px) {
        .__global_text{
           grid-template-columns: 1fr;
        }
        
      }
      .__global_map {
        width: 100%;
        img {
          width: 100%;
        }
      }
      @media screen and (max-width:1000px)  {
        .__global_map{
          padding: 20px 20px;
        }
      }
    }
  }
  .__about_home {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .__about_home_text {
      padding: 40px 200px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: linear-gradient(0.25turn, #00000000, #000000c7, #00000000);
      height: 500px;
    }
    @media screen and (max-width:1000px) {
      .__about_home_text{
padding: 40px 50px !important;
      }
      
      
    }
    p {
      color: white !important;
      text-align: center;
    }
  }
  .__retails_wrap {
    padding: 100px 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;

    .__retails {
      width: 100%;
      height: auto;
      h1 {
        padding-bottom: 50px;
        span {
          color: black;
        }
      }
      .__logos {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        .__logo {
          width: 100%;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          img {
            width: 100px;
          }
        }
      }
      @media screen and (max-width: 992px) {
        .__logos {
          grid-template-columns: 1fr 1fr 1fr;
          .__logo {
            img {
              width: 50px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .__ceo {
      grid-template-columns: 1fr;
    }
  }
}

PlayCircleOutlineIcon {
  background-color: black !important;
}