@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");
body {
  width: 100vw !important;
  height: auto;
  display: flex;
  text-align: center !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}
html{
  scroll-behavior: smooth;
  width: 100vw !important;
  
}
@font-face {
  font-family: "CustomFont";
  src: url("../src/fonts/OPTIMA.TTF") ;
  src: url("../src/fonts/OPTIMA.woff") format("woff") ;
  src: url("../src/fonts/OPTIMA.woff2") format("woff2") ;
}



button {
  border: none;
  background:none;
  font-size: 14px;
  color: #fcb315 !important;
}
button:hover{
  color: black!important;
}

h1, h2, h3, h4, h5, h6, .mil-h1, .mil-h2, .mil-h3, .mil-h4, .mil-h5, .mil-h6 {
  font-family: "CustomFont" !important;
    color: rgb(56, 40, 38) !important;
    font-weight: 400 !important;

    }


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none ;
}
p {
  color: rgb(94, 94, 94) !important;
  font-size: 14px !important;
}
h1 {
  font-size: 2.5em !important;
  color: #fcb315 !important;
  line-height: 1.1 !important;
}
h2 {
  font-weight: 800 !important;
  line-height: 1.2 !important;
  font-size: 1.5em !important;
}
h3 {
  font-size: 1.17em !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}
Link {
  text-decoration: none !important;
}
a {
  text-decoration: none !important;
}
.span {
  font-weight: 900 !important;
}

.__container {
  margin: auto;
  max-width: 1300px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  object-fit: contain;
  height: auto;
  margin: auto;
}

.btn {
  cursor: pointer;
  border: none;
  margin-top: 20px;
  width: 175px;
  height: 50px;
  border-radius: 50px;
  color: rgb(0, 0, 0);
  transition: 0.3s ease-in;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(219, 219, 219);
}
.btn:hover {
  color: #000000 !important;
  background-color: #fcb315;
}

.btn_two {
  border: none;
  margin-top: 20px;
  width: 175px;
  height: 50px;
  border-radius: 50px;
  background-color: #fcb315;
  color: rgb(0, 0, 0) !important;
  transition: 0.3s ease-in;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.btn_two:hover {
  color: #000000;
  background-color: #ffffff;
}
Link{
  color: black !important;
}
a{
   color: black !important;

}
a:hover{
   color: rgb(155, 155, 155) !important;

}

input{
  outline: none !important;
}
select{
  outline: none !important;
}
textarea{
  outline: none !important;
}
input[type=file] {
  width: 100% !important;

}
input[type=radio] {
  width: 30px !important;


}

.__fw {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__mw {
  max-width: 1200px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
}