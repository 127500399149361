.catalog {
  align-items: center;
  max-width: 1200px !important;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .book_tab {
    padding: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    .book_card {
      width: 100%;
      height: auto;
    }
    .book_card:hover {
      transform: translateY(-10px);
      transition: 0.2s ease-in;
      touch-action: pan-up !important;
    }
  }
  @media screen and (max-width: 920px) {
    .book_tab {
      grid-template-columns: 1fr;
    }
  }

  .book {
    // padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: auto;
    .demoPage {
      height: auto !important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: auto;
      overflow: hidden;
      .icon_img {
        width: 100px;
      }
      img {
        width: 100%;
      }
      .cat_down {
        width: 100%;
        height: auto !important;
        padding: 50px;
      }
    }
  }
}
.down {
  // width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .down {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

.catalog_wrap {
  width: 100%;
  height: 80vh;
}

.location {
  width: 100%;
  margin-bottom: 60px;
  margin-top: 60px;
  img {
    width: 100%;
  }
}
