.rsm-svg {
  width: 100% !important;
}

path {
  fill: hsl(205, 100%, 85%);
  outline: none;
}
.map_container {
      position: relative;
         height: 50vh;
         height: auto;
         .con_sub{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 30px;
        }
        @media screen and (max-width:992px) {
  
  .con_sub{
    grid-template-columns: 1fr 1fr 1fr ;

  }
}
  .__world_map {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 10px 40px;

  }

    .country_name {
      border-radius: 10px;
      text-align: left;
      padding: 20px;
      bottom: 0;
      z-index: 10;
      width:100%;
      height: auto;
      position: absolute;
      align-items: center;

      h4{
        font-size: 16px;
        color: #fcb315 !important;
        text-decoration: underline ;
      }
      .con_name{
        ul li{
          font-size: 10px;
        }
        
      }
  
    }
    @media screen and (max-width:992px) {
      .country_name{
        display: none;
      }
      
    }
}


.tooltip {
  color: #ff6e00 !important;
  background-color: orange !important;
}
.map_color {
  stroke: hsl(205, 92%, 76%);
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}
#react-root {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

section {
  margin-bottom: 50px;
}

a {
  display: inline-block;
}

.target {
  text-decoration: underline;
  cursor: pointer;
}

.target .react-tooltip-lite {
  cursor: default;
}

.flex-spread {
  display: flex;
  justify-content: space-between;
}

.tip-heading {
  margin: 0 0 10px;
}

.tip-list {
  margin: 0;
  padding: 0 0 0 15px;
}

.tip-list li {
  margin: 5px 0;
  padding: 0;
}

/* tooltip styles */
.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite a {
  color: #86b0f4;
  text-decoration: none;
}

.react-tooltip-lite a:hover {
  color: #4286f4;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
  border: 1px solid #888;
  background: #ccc;
  color: black;
}

.customTip .react-tooltip-lite-arrow {
  border-color: #444;
  position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.imageWrapper {
  margin: 50px 0 0;
  position: relative;
}

.imageWrapper img {
  width: 500px;
  height: 500px;
}

.controlled-example {
  max-width: 250px;
}

.controlled-example_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.controlled-example_close-button {
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 0;
}

.controlled-example_close-button:hover {
  color: grey;
}

.internal-scroll-container {
  height: 200px;
  overflow: auto;
}

.internal-scroll-container > div {
  padding-top: 100px;
  height: 400px;
}

.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.arrow-content-tooltip .react-tooltip-lite-down-arrow svg {
  transform: translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-right-arrow svg {
  transform: rotate(270deg) translateY(-4px) translateX(-4px);
}
.arrow-content-tooltip .react-tooltip-lite-up-arrow svg {
  transform: rotate(180deg) translateY(1px);
}
.arrow-content-tooltip .react-tooltip-lite-left-arrow svg {
  transform: rotate(90deg) translateY(5px) translateX(4px);
}
