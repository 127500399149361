table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px !important;
  position: relative !important;
  margin-top: 20px;
}
strong{
  color: #007bffb2;
  font-weight: 400 !important;
}
.main_tb_wrp{
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}
small{
  font-size: 12px;
  color: gray !important;
}
.action{
  width: 100%;
  h4{
    font-size: 20px;
    padding: 0 !important;
    margin: 0!important;
    line-height: 1 !important;
  }
  span{
    font-size: 13px;
    font-weight: 600;
    text-transform:capitalize;
  }
}

.icon:hover {
  cursor: pointer;
  background-color: #afceff63;
  color: Black !important;
}

td,
th {
  // border: 1px solid #afdeff;
  // border-bottom:1px solid #afdeff;
  // border-right: 1px solid #afdeff;
  text-align: left;
  padding: 8px;
}

th {
  border-bottom: 1px solid #afdeff;
}
tr {
  max-height: 40px;
  overflow: hidden !important;
  height: 40px !important;
}
tr:nth-child(even) {
  background-color: #afdeff3c;
}
.export_button{
  margin-top: 20px;
  button{
    border-radius: 5px;
    padding: 6px 10px;
    background-color: #afdeff;
    color: black !important;
  }
  button:hover{
    background-color: #80c9fcf1;
    color: rgb(255, 255, 255) !important;
  }
}

.data_table {
  padding: 20px 0px;
  max-width: 1200px;
  overflow-x: scroll;
  position: relative !important;
  display: flex;
  flex-direction: column;
  .tb_head{
    //position: sticky !important;
    //width: 100%;
    top:70px;
    width: 1200px !important;
    position: fixed;
    background-color: #dff2ff;
    tr{
      width: 100%;
      display: flex;
      max-width: 1200px;
    }
    
  }

}


.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;

    &.active {
      background-color: #007bff;
      color: white;
    }

    &:disabled {
      background-color: #e0e0e0;
      cursor: not-allowed;
    }
  }
}
.popUp {
  height: 100vh;
  width: 100vw;
  z-index: 99999999 !important;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(219, 219, 219, 0.8);
  top: 0;
  left: 0;
  display: flex;
  .close {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    align-items: flex-end;
    cursor: pointer;
  }
  .popEdit {
    max-width: 100%;
    padding: 30px;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    .popuInfo {
      width: 100%;
      height: auto;
      gap: 20px;
      max-width: 600px;
      min-width: 600px;
      .moreInfo_wrp{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        .meg_wrp{
          margin-top: 10px;
          width: 100%;
          height: auto;
          padding: 12px 20px;
          max-height: 200px;
          box-sizing: border-box;
          // border: 1px solid #afdeff;
          border-radius: 4px;
          background-color: #afceff37;
          font-size: 16px;
          overflow: scroll;


        }
        .moreInfo_contact{
          text-align: left;
          padding: 20px;

        }
      }

      .popUpHead {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        text-align: left;
      }
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        .pop_radio {
          width: 100%;
          align-items: center;
          display: flex;
          gap: 30px;
          margin-bottom: 20px;
        }
        textarea {
          width: 100%;
          height: 150px;
          max-width: 600px;
          min-width: 600px;
          padding: 12px 20px;
          box-sizing: border-box;
          border: 1px solid #afdeff;
          border-radius: 4px;
          background-color: #afceff63;
          font-size: 16px;
          resize: none;
        }
        .submit {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
        }
      }
    }
  }
}
