.ser-container-why {
    width: 100%;
    height: auto;
    padding: 0rem 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.my-div {
    width: 100%;
}

.button-section {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2rem;
    grid-gap: 2rem;
}
@media screen and (max-width:1000px) {
    .button-section{
          grid-template-columns: 1fr;

    }
    
}


.my-div h1 {
    color: #fcb315;
    font-weight: 800;
}

.my-div h3 {
    font-size: 30px;
}

.why-heading {
    width: 100%;
    height: auto;
    padding-top: 40px;
}

.primary-btn-why {
    border: none;
    width: 100%;
    margin: 0px 50px;
    padding-bottom: 10px;
    background: none;
    font-size: 20px;
    border-bottom: 1px solid rgb(200, 200, 200);
    color: rgb(200, 200, 200);
}

.primary-btn-why:hover {
    color: #fcb315;
    border-bottom: 1px solid #fcb315;
}

.primary-btn-why:focus {
    color: #fcb315;
    border-bottom: 1px solid #fcb315;
}

.btn-back {
    height: 3px;
    width: 50%;
    background-color: #fcb315;
}

.btn-back-right {
    height: 3px;
    width: 50%;
    background-color: #fcb315;
    display: flex;
    float: right;
}

.btn-one {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: auto;
}

.btn-two {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: auto;
}
.btn-three {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: auto;
}
.slide-img-icon {
    width: 100%;
    height: auto;
}
.__logos {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 50px;
        padding: 20px;
        min-height: 350px;
        .__logo {
          width: 100%;
          height: auto;
          align-items: center;
          justify-content: center;
          display: flex;
          img {
            width: 100px;
          }
        }
    }
    @media screen and (max-width:1000px) {
        .__logos {
                grid-template-columns: 1fr 1fr ;

        }
        
    }


 .__partners{
    width: 100%;
    height: auto;
    padding: 100px 40px;
 }   