.__contact {
  width: 100%;
  height: auto;
  .__cont_head {
    width: 100vw;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;

    h1 {
      font-size: 50px !important;
      color: white !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
  }
  .cont_wrap {
    width: 100%;
    .container {
      .contact {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
        padding: 60px 0px;

        .contact_info_wrap {
          text-align: left!important;
          padding: 60px;
          width: 100%;
          height: auto;
          .con_data {
            padding-bottom: 20px;
          }

          h1 {
            font-size: 3rem;
            padding-bottom: 20px;
          }
          .contact_info {
            text-align: left!important;
            padding: 10px 30px;
            width: 100%;
            height: auto;
            display: grid;
            grid-template-columns: 1fr 3fr;
          }
        }
        .contact_form {
          text-align: left;
          padding: 10px;
          width: 100%;
          height: auto;
          background-color: #f8f7fa;
          .redio_con{
            display: grid;
            grid-template-columns: 2fr 1fr;
          }
          .con_sel{
      padding:10px 20px;
             margin: 10px 0px;
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #f0efef;

          }
          input {
            margin: 10px 0px;
            width: 100%;
            padding:10px 20px;
            border-radius: 30px;
            border: 1px solid #f0efef;
          }
          .form_in {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2rem;
          }
          textarea {
            font-family: Helvetica, sans-serif;

            width: 100%;
            height: 100px;
            padding: 10px 10px;
            border-radius: 20px;
            border: 1px solid #f0efef;
          }
          button {
            border: 1px solid #f0efef;
            padding: 10px 30px;
            border-radius: 30px;
            color: white;
          }
          button:hover{
            transition: .2s ease-in-out;
            background-color: orange;
            color: black !important;
          }
        }
      }
      @media only screen and (max-width: (992px)) {
        .contact {
          grid-template-columns: 1fr;
          padding: 10px !important;
          .contact_info_wrap {
            padding: 20px;
            .contact_info {
              padding: 20px !important;
              .con_data {
                padding: 20px;
              }
              .con_icon {
                padding: 5px !important;
              }
            }
          }
          .contact_form {
            padding: 10px;
          }
        }
      }
    }
  }
}
