.__nav_map{
    width: 100%;
    height: 30px;
    background-color: rgb(234, 234, 234);
    .__container{
        .__nav_map_link{
            width: 100%;
            align-items: center;
            justify-content: start;
            display: flex;
            padding: 4px 50px ;

        }

    }
}