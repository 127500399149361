.__laronda {
  width: 100%;
  height: auto;

  .__laronda__fac__wrap {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 300px;
    .__factory_banner {
      width: 100%;
      overflow: hidden;
    }
    .__container {
      .__fac__head {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        h1 {
          color: #ffffff !important;
          font-size: 50px !important;
          font-weight: 600 !important;
          filter: saturate(5px) !important;
        }
      }
    }
  }
  .__factory_all {
    display: flex;
    padding: 100px 30px 50px 30px;
    width: 100%;
    .factory_list {
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px;
      .factory_card {
        cursor: pointer;
        -webkit-transition: all 250ms ease-out;
        transition: all 250ms ease-out;
        border-radius: 10px;
        align-items: center;

        border: 1px solid #fcb315;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        img {
          width: 200px;
        }
      }
      .factory_card:hover {
        transform: translateY(-5px);
       transition: .3s ease-in-out;
          -webkit-box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);
          -moz-box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);
          box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);

           h3:hover {
         color:#fcb315 !important ;
          transition: .3s ease-in-out;

      }
      }
     
    }
    @media screen and (max-width: 992px) {
      .factory_list {
        grid-template-columns: 1fr;
      }
    }
  }
  .__container {
    .__fac__qut {
      padding: 100px 40px;
      grid-gap: 100px;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .__qut_text {
        width: 100%;
        height: auto;
        span {
          color: rgb(255, 255, 255) !important;
        }
        h1 {
          margin-bottom: 20px !important;
        }
        p {
          color: rgb(255, 255, 255) !important;
        }
      }
      .__qut_img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        overflow: hidden;
        object-fit: contain;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .__fac__qut {
        grid-template-columns: 1fr;
      }
    }
    .__certificate_wrap {
      padding: 30px 30px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      h1 {

        margin: 30px 0px; 
      }
      .__certifi {
        align-items: center;
        justify-content: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        height: auto;
        .__certi__card {
         // background-color: rgb(249, 249, 249);
          padding: 10px 30px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 30px;
          img {
            margin: 30px;
            width: 150px;
            height: auto;
          }
          .__certi__text {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: end;
            align-items: center;
          }
        }
      }
      @media screen and (max-width: 992px) {
        .__certifi {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .__warehousing {
    padding: 0 50px;
    margin: 40px 0px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .__warehousing_info {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      .__werehousing_head {
        padding: 40px 0px;
        h1 {
          color: white !important;
        }
      }

      .__warehousing_list {
        width: 100%;
        display: grid;
        padding: 0px 40px;
        grid-gap: 40px;
        grid-template-columns: 1fr 1fr;
        .__uae {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          padding: 30px;
          width: 100%;
          height: auto;
          background-color: rgba(0, 0, 0, 0.22);
          p {
            color: rgb(255, 255, 255) !important;
            margin-top: 20px;
            font-style: italic;
            span {
              color: #fcb315 !important;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
        .__ksa {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          padding: 30px;
          width: 100%;
          height: auto;
          background-color: rgba(0, 0, 0, 0.22);
          p {
            color: rgb(255, 255, 255) !important;
            margin-top: 20px;
            font-style: italic;
            span {
              color: #fcb315 !important;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
      @media screen and (max-width: 992px) {
        .__warehousing_list {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .__human_re {
    padding-bottom: 100px;
    width: 100%;
    padding: 0 40px;
    height: auto;

    .__human_head {
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 30px;
      span {
        color: black;
      }
    }
    .__human_text {
      width: 100%;
      text-align: center;

      .__human_text_div_top {
        height: auto;
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0px 80px;

        .__item_s {
          width: 100%;
          height: auto;
          align-items: center;
          display: flex;
          flex-direction: column;
          padding: 10px 0px;
          justify-content: center;
          h2 {
            margin-bottom: 20px;
            color: #fcb315;
          }
          span {
            color: black;
          }
        }
        
      }
      @media screen and (max-width: 992px) {
          .__human_text_div_top {
            grid-template-columns: 1fr;
            padding: 0px;
          }
        }

      .__human_text_div {
        width: 100%;
        padding: 40px;
      }
    }
  }
}
