.brands {
  width: 100%;
  height: auto;
  padding: 50px 100px;

  .brandCard {
    padding: 30px 0px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    .cardItem {
      padding: 10px;
      width: 100%;
      height: 300px;
      //background-color: rgb(182, 182, 182);

      cursor: pointer;
      overflow: hidden;
      display: flex;
      justify-content: center;
      .cardIn {
        border-radius: 10px !important;
        width: 100%;
        height: auto;
        object-fit: contain;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        border: 2px solid rgb(255, 255, 255);
        img {
          border-radius: 10px;
          object-fit: contain;
          overflow: hidden;
          height: auto;
          width: 100%;
        }
        
      }
      .cardIn:hover {
        transition: .3s ease-in-out;
          -webkit-box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);
          -moz-box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);
          box-shadow: 0px 0px 9px 1px rgba(252, 179, 21, 1);
        }
    }
    .cardItem:hover{
         transform: translateY(-5px);
        transition: .3s ease-in-out;
    }
  }
  @media screen and (max-width:992px) {
  .brandCard {
    grid-template-columns: 1fr;
  }

    
  }
}
