.confectionery_mlb {
  width: 100vw;
  height: auto;
  .__con_head{
    padding: 40px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .__con_main_cat {
    padding: 10px 40px 100px 40px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 60px;
    .__con_cat_card {
     overflow: hidden;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      -webkit-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      
      .__con__cat_text{
        background-color: #fcb315;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        border-radius:0px 0px 8px 8px;
        h2{
          padding-top: 10px;
         font-size: 20px !important;
         color: black;
         font-weight: 300 !important;
         transition: .2s ease-in ;
        }
       
      }
      
      .__con_cat_img {
        display: flex;
        align-items: center;
      justify-content: center;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .__con_cat_card:hover {
      filter: opacity(5);
      -webkit-transform: translateY(-1px);
      transform: translateY(-10px);
      -webkit-box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      
    }
  }
  @media screen and (max-width:1000px) {
    .__con_main_cat{
      grid-template-columns: 1fr 1fr ;
      grid-gap: 20px;
      
    }
    
  }
  .conf_wrap {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    padding: 0px 40px 100px 40px !important;
    .conf_txt {
      .conf_text_head {
        padding-bottom: 15px;
        h3{
          font-weight: 600 !important;
          color: rgb(0, 0, 0);
        }
      }
    }
    .conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width:1000px) {
     .conf_wrap{
      grid-template-columns: 1fr ;
      .I_a{
        order: 2;
      }
      .I_b{
        order: 1;
      }

     }
    
  }
}
