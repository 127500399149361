.confectionery_sn {
  width: 100%;
  height: auto;
  .__con_head{
    padding: 40px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .__con_main_cat {
    padding: 10px 40px 100px 40px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px !important;
    .__con_cat_card_b {
      overflow: hidden;
      cursor: pointer;
      text-align: center;
      -webkit-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      display: flex;
      flex-direction: column;
      width: 100%;
      .__con__cat_text{
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        h2{
          font-size: 20px !important;
          padding-top: 10px;
         color: black;
         font-weight: 300 !important;
         transition: .2s ease-in ;
   
         
        }
       
      }
      
      .__con_cat_img {
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
      justify-content: center;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .__con_cat_card_b:hover {
      filter: opacity(5);
      -webkit-transform: translateY(-1px);
      transform: translateY(-10px);

      
    }
  }
  @media screen and (max-width:1000px) {
    .__con_main_cat{
      grid-template-columns: 1fr 1fr ;
      grid-gap: 20px;
      
    }
    
  }
  .conf_wrap {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    padding: 100px 40px !important;
    .conf_txt {
      text-align: center !important;
      .conf_text_head {
        padding-bottom: 15px;
        h3{
          font-weight: 600 !important;
          color: rgb(0, 0, 0);
        }
      }
    }
    .conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width:1000px) {
     .conf_wrap{
      grid-template-columns: 1fr ;

     }
     .I_a{
      order: 0;
     }
      .I_b{
      order: 1;
     }
      .I_c{
      order: 4;
     }
      .I_d{
      order: 3;
     }
      .I_e{
      order: 5;
     }
      .I_f{
      order: 5;
     }
      .I_g{
      order: 8;
     }
      .I_h{
      order:7;
     }
     
    
  }
}
