.__about_wrap {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .__pvt_head {
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;

    width: 100vw !important;
    height: 300px;
    h1 {
      color: #fcb315 !important;
          font-size: 50px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;

      span {
        color: white;
      }
    }
  }
  

  .__about_head_text {
    width: 100%;
    padding: 100px 40px;
    text-align: center;
    span{
      color: #fcb315;

    }
  }
  @media screen and (max-width:1000px){
    .__about_head_text{
       padding:40px !important;
    }
    
  }
  .__time_head {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }

  .__container {
    .__about__wrap {
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
   
    .__about__mission {
      width: 100%;
      display: grid;
      padding: 50px 0px;
      gap: 50px;
      text-align: center;

      .__about_item_warp{
        display: flex !important;
        gap: 50px;
        .__about__item {
        h2 {
          color: #fcb315;
          padding-bottom: 20px;
        }

        span {
          color: black;
        }
        .__icon {
          margin-bottom: 30px;
          font-size: 80px;
          color: lightgray;
          border: 2px solid lightgray;
          padding: 15px;
          border-radius: 50%;
        }
      }
      

      }
      @media screen and (max-width:1000px) {
        .__about_item_warp{
        flex-direction: column!important;
        padding: 0px 40px ;
        }
        
      }

      
    }
    @media screen and (max-width: 992px) {
      .__about__mission {
        grid-template-columns: 1fr;
      }
    }
  }

  .__companices {
    background-color: rgb(249, 249, 249);
    padding: 50px 40px;
    width: 100%;
    height: auto;
    .___companices__head {
      padding-bottom: 60px;
      span {
        color: black;
      }
    }

    .__companies__wrap {
      width: 100%;
      height: auto;
      display: grid;
      gap: 50px;
      grid-template-columns: 1fr 1fr 1fr;
      .company_text {
        text-align: center;
      }
      img {
        margin-bottom: 40px;
        width: auto;
        height: 100px;
      }
    }
    @media screen and (max-width: 992px) {
      .__companies__wrap {
        grid-template-columns: 1fr;
      }
    }
  }
  .__about__vision {
    padding: 100px 10px;

    width: 100%;
    height: auto;

    .__container {
      .__about__items {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;

        .__vision_item {
          padding: 10px;
          border-radius: 30px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          .__vision_item_in {
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            background-color: rgba(0, 0, 0, 0.351);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: drop-shadow(4px 4px 10px rgb(255, 128, 0));

            padding: 40px;
            border-radius: 30px;
            h1 {
              padding-bottom: 10px !important;
               color: #fcb315 !important;

              text-transform: unset !important;
            }
            p {
              color: rgb(255, 255, 255) !important;
              font-style: italic !important;
            }
          }
        }
      }
      @media screen and (max-width: 992px) {
        .__about__items {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .__time__line {
    padding: 50px 40px;
    width: 100%;
    height: auto;

    .__container {
      .__time__head_sec {
        margin-bottom: 80px;
        margin-top: 10px;
      }
      .__time__contents {
        width: 100%;
        height: auto;
        .__time_top {
          align-items: center;
          justify-content: center;
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 50px;

          .__time_card_min {
            width: 100%;
            height: auto;
            position: relative;
            .__time_card_out {
              z-index: -2;
              position: relative;
              display: flex;
              align-items: flex-end;
              width: 100%;
              height: 200px;
              border-left: 3px solid rgb(0, 0, 0);
              border-bottom: 3px solid rgb(0, 0, 0);
            border-left: dashed;
              .__round{
                position: absolute;
                top: 0;
                left: 0;
                margin: -6.5px;
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: gray;
              }
            }
            
            .__time__card {
              top: 0;
              display: flex;
              flex-direction: column;
              position: absolute;
              background-color: rgb(255, 255, 255);
              width: 100%;
              padding: 20px;
              height: auto;
              margin-left: 15px;
              margin-top: 20px;
              border-radius: 20px;
              z-index: -1;

              .__icon {
                font-size: 60px;
                padding-bottom: 20px;
                color: gray;
              }
            }
          }
        }
        @media screen and (max-width:1000px) {
          .__time_top{
             grid-template-columns:  1fr;

          }
          
        }
        .__time__center__line {
          position: relative;
          width: 100%;
          height: 3px;
          display: flex;
          border-top: 3px solid gray;
          margin: 80px 0px;
          background-color: gray;
          .__round{
                position: absolute;
                left: 0;
                bottom: -3.5px;
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: gray;
              }

               .__round_two{
                position: absolute;
                right: 0;
                bottom: -3.5px;
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: gray;
              }
        }
         .__time_bottom {
          align-items: center;
          justify-content: center;
          width: 100%;
          display: grid;
          grid-template-columns:1fr 1fr 1fr  ;
          grid-gap: 50px;
          margin: 0px 200px;

          .__time_card_min {
            width: 100%;
            height: auto;
            position: relative;
            .__time_card_out {
              z-index: -2;
              position: relative;
              display: flex;
              align-items: flex-end;
              width: 100%;
              height: 200px;
              border-left: 3px solid rgb(0, 0, 0);
              border-top: 3px solid rgb(0, 0, 0);
            border-left: dashed;
              .__round{
                position: absolute;
              bottom: 0;
                left: 0;
                margin: -6.5px;
                width: 10px;
                height: 10px;
                border-radius: 50px;
                background-color: gray;
              }
            }
            
            .__time__card {
              top: 0;
              display: flex;
              flex-direction: column;
              position: absolute;
              background-color: rgb(255, 255, 255);
              width: 100%;
              padding: 20px;
              height: auto;
              margin-left: 15px;
              margin-top: -80px;
              z-index: -1;

              .__icon {
                font-size: 60px;
                padding-bottom: 20px;
                color: gray;
              }
            }
          }
        }
        @media screen and (max-width:1000px) {
          .__time_bottom{
             grid-template-columns:1fr ;
              margin: 0px !important;

          }
          
        }
      }
    }
  }

  .__time_line {
    width: 100%;
    height: auto;
    padding: 100px 50px;
    .__container {
      .__timeline__wrap {
        align-items: center;
        display: flex;
        flex-direction: column;
        h1 {
          padding-bottom: 40px;
        }

        span {
          color: black;
        }

        .__time_line_cards {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-gap: 30px;
          .__time_line_card {
            background-color: rgb(249, 249, 249);
            border-radius: 10px;
            width: 100%;
            height: auto;
            padding: 20px;
            align-items: center;
            justify-content: start;
            display: flex;
            flex-direction: column;
            text-align: center;
            .__icon {
              margin-bottom: 30px;
              font-size: 80px;
              color: #fcb315;
              padding: 15px;
            }
            h2 {
              padding-bottom: 20px;
              line-height: 1;
              color: #fcb315;
            }
            .__arrow {
              margin: 0px -50px;
              margin-bottom: 20px;
              align-items: center;
              justify-content: center;
              padding: 10px 10px;
              width: 100%;
              height: auto;
              background-color: lightgray;
              border-radius: 50px;
              display: grid;
              grid-template-columns: 3fr 1fr;
              .__arrow_item {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                span {
                  text-transform: uppercase;
                  font-weight: 600;
                  line-height: 1;
                  font-size: 20px;
                }
              }
            }
          }
        }
        @media screen and (max-width: 992px) {
          .__time_line_cards {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  .__menegment__wrap {
    background-color: rgb(249, 249, 249);
    padding: 50px 40px;
    width: 100%;
    height: auto;
    .__container {
      span {
        color: black;
      }
      .__menegment__wrap__in {
        padding: 50px 0px;
        height: auto;
        width: 100%;
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .__menegment_card {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          text-align: center;

          img {
            width: 130px;
            border-radius: 100%;
            margin-bottom: 20px;
          }
          span {
            font-size: 16px;
            color: #fcb315;
          }
        }
      }
      @media screen and (max-width: 992px) {
        .__menegment__wrap__in {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
