.login{
    width: 100%;
    height: 60dvh;
    align-items: center;
    justify-content: center;
    display: flex;
     flex-direction: column;

}
.reg{
     width: 100%;
    height: 60dvh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

}