.career {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .__cr_head {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 300px;

    h1 {
      font-size: 50px !important;
      color: white !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
  }
  .head_car {
    padding-top: 50px;
  }

  .career_in {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .cr_dec{
      width: 100%;
      height: auto;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1{
        line-height: 1 !important;
      }
    }

    .__exp__form__con {
      align-items: center;
      display: flex;
      align-items: center;
      text-align: left !important;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 5px 30px;

      input,
      textarea {
        outline: none;
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);

        .placeholder {
          font-size: 12px;
          color: rgb(238, 238, 238) !important;
        }
      }
      input::placeholder {
        color: rgb(160, 160, 160);
        font-size: 14px;
      }
      .con_input {
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);
        .__country__select {
          width: 100%;
          background: none;
          border: none;
          outline: none;
        }
      }

      .input_con {
        width: 100%;
      }
      .__exp__form {
        width: 100%;
        background-color: white !important ;
        padding: 30px;
        form {
          width: 100%;
          height: auto;

          .from_sec {
            gap: 30px;
            padding: 10px 0px;
            .from_sec_in {
              display: flex;
              gap: 20px;
            }
          }
        }
      }
    }
  }
}
