.__private_labels {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;

  .__pvt_head {
    width: 100vw !important;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 300px;
    h1 {
          font-size: 50px !important;
    color: white !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;

      span {
        color: white;
      }
    }
  }
  .__exp__form__con {
      align-items: center;
      display: flex;
      text-align: left !important;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: auto;

      
      input,textarea {
        outline: none;
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);
        

        .placeholder {
          font-size: 12px;
          color: rgb(238, 238, 238) !important;
        }
      }
      input::placeholder{
        color: rgb(160, 160, 160);
        font-size: 14px;
      }
      .con_input {
        border-radius: 5px;
        width: 100%;
        padding: 5px 10px;
        margin-bottom: 20px;
        border: none;
        background-color: rgb(244, 244, 244);
        .__country__select{
          width: 100%;
          background: none;
          border: none;
          outline: none;
        }
        
      }

      .input_con {
        width: 100%;
      }
      .__exp__form {
        width: 100%;
        background-color: white !important ;
        padding: 30px;
        form {
          width: 100%;
          height: auto;

          .from_sec {
            gap: 30px;
            padding: 10px 0px;
            .from_sec_in {
              display: flex;
              gap: 20px;
            }
          }
        }
      }}
  .__pvt__form{
    align-items: center;
    display: grid;
    width: 100%;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;

  } 

  .__pvt__section {
    grid-gap: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: auto;
    padding: 100px 40px 50px 40px;
  }
  @media screen and (max-width: 992px) {
    .__pvt__section {
      grid-template-columns: 1fr;
    }
    .__pvt__form{
       grid-template-columns: 1fr;

    }
  }
  .__pvt_icon {
    padding: 50px 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;

    .__pvt_icon_section {
      width: 100%;
      height: auto;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: start;

      h2 {
        color: #fcb315;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .__pvt_icon {
      grid-template-columns: 1fr;
    }
  }
  .__pvt__retail {
    padding: 50px 40px;
    width: 100%;
    height: auto;
    span {
      color: black;
    }

    .__pvt_retail_section {
      padding: 50px 0px;
      width: 100%;
      display: grid;
      grid-gap: 50px;

      grid-template-columns: 1fr;
      .__pvt_icon_section {
        width: 100%;
        padding: 50px 80px;

        .__icon_logos {
          padding-bottom: 30px;
          width: 100%;
          height: auto;

          img {
            width: 150px;
            padding: 20px;
          }
        }
      }
      @media screen and (max-width:1000px) {
        .__pvt_icon_section{
            padding: 50px 0px;
        }
      }
    }
  }
}
