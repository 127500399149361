.duty_free {
  width: 100%;
  height: auto;
  .__duty_head {
    width: 100vw;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;

    h1 {
      font-size: 50px !important;
      color: #fbfbfb !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
  }
  .__duty_form_con {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .gift_wrap {
    width: 100vw;
    background-color: #fbfbfb;
    .gift_sec {
      width: 100%;
      height: auto;
      padding: 100px 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px;
      .gift_text {
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      .gift_img {
        padding: 50px;
        img {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .gift_sec {
        grid-template-columns: 1fr;
      }
    }
  }

  .__exp__form__con {
    align-items: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left !important;
    width: 100%;
    height: auto;
    padding: 30px;

    input {
      outline: none;
      border-radius: 5px;
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 20px;
      border: none;
      background-color: rgb(244, 244, 244);

      .placeholder {
        font-size: 12px;
        color: rgb(238, 238, 238) !important;
      }
    }
    input::placeholder {
      color: rgb(160, 160, 160);
      font-size: 14px;
    }
    .con_input {
      border-radius: 5px;
      width: 100%;
      padding: 5px 10px;
      margin-bottom: 20px;
      border: none;
      background-color: rgb(244, 244, 244);
      .__country__select {
        width: 100%;
        background: none;
        border: none;
        outline: none;
      }
    }

    .input_con {
      width: 100%;
    }
    .__exp__form {
      width: 100%;
      background-color: white !important ;
      padding: 30px;
      form {
        width: 100%;
        height: auto;

        .from_sec {
          gap: 30px;
          padding: 10px 0px;
          .from_sec_in {
            display: flex;
            gap: 20px;
          }
        }
      }
    }
  }

  .__duty_free {
    .__duty_subhead {
      padding: 100px 0px;
      width: 100%;
      text-align: left;
      grid-gap: 50px;
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      span {
        color: black;
      }
    }
    @media screen and (max-width: 900px) {
      .__duty_subhead {
        grid-template-columns: 1fr;
        padding: 40px;
      }
    }
    .__duty_f_imgs {
      padding: 50px 0px;
      width: 100%;
      height: auto;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    @media screen and (max-width: 900px) {
      .__duty_f_imgs {
        padding: 40px;
        grid-template-columns: 1fr;
      }
    }
  
  }
}

  .__con_form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .__duty_text {
        padding: 50px 100px 100px 100px;
        h2 {
          color: #fcb315;
        }
      }
      @media screen and (max-width: 992px) {
        .__duty_text {
          padding: 40px !important;
        }
      }
    }
    @media screen and (max-width: 992px) {
        .__con_form {
           grid-template-columns: 1fr;
        }
      }
