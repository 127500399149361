.confectioneryBrand {
  display: flex;
  align-items: center;
  flex-direction: column;
 padding: 20px 40px;
  width: 100vw !important;
  height: auto;
  .conf_head_br{
    padding-bottom:40px ;
    width: 100%;
    padding: 20px 100px 100px 100px !important;
    text-align: center;
    h1{
       font-size: 50px !important;
    }
  }
  @media screen and (max-width:1000px) {
     .conf_head_br{
         padding: 0px 40px 20px 40px ;
     }
    
  }
  h2 {
    padding: 20px 0px;
  }
  .confBrand_hero {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: white;
    }
  }
  .__brand{
    padding: 80px;
    width: 100%;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr ;
    .__brand_img{
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        
        img{
            width: 100%;

        }
    }  
    .__brand_text{
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: auto;
        img{
            width: 200px;
            margin-bottom: 10px;
        }
        .brand_icons{
            padding-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 100%;
            height: auto;
            grid-gap: 30px;
            align-items: center;
            justify-content: center;
            img{
                padding: 20px;
                width: 100px;
            }
        }
        @media screen and (max-width:1000px) {
          .brand_icons{
             grid-template-columns: 1fr 1fr ;
          }
          
        }
    }
  }
  @media screen and (max-width:1000px) {
    .__brand{
         grid-template-columns:  1fr ;

    }
    
  }
}
.confBrand {
  padding: 0px 40px;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  .confSectionLeft {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: start;
    text-align: right;
    img{
        padding: 40px;
      width: 100%;
    }
  }
  @media screen and (max-width:1000px) {
    .confSectionLeft{
      width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    }
    
  }
  .confSectionCenter {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .confSectionRight {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items:start ;
    justify-content: start;
    text-align: left;
    img {
      padding: 40px;
      width: 80%;
    }
  }
  
}
@media screen and (max-width:1000px) {
    .confBrand{
        grid-template-columns:  1fr;
       
    }
     .__brand{
          .I_a{
            order: 1;
          }
          .I_b{
            order: 2;
          }
          .I_c{
            order: 4;
          }
          .I_d{
            order: 3;
          }
           .I_e{
            order: 5;
          }
           .I_f{
            order: 6;
          }
           .I_i{
            order: 8;
          }
           .I_j{
            order: 7;
          }
          
        }
    
}
