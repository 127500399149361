.__retails {
    padding: 100px 0px;
    width: 100%;
    height: auto;
    .__retails_btn{
        width: 100%;
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
    }
  .__retails_text {
    width: 100%;
    height: auto;
    position: relative;
    .section_one {
      width: 100%;
      height: auto;
      position: absolute;
      background-color: grey;
      top: 0;
    }
    .section_two {
      width: 100%;
      height: auto;
      position: absolute;
      background-color: rgb(255, 87, 87);
      top: 0;
    }
    .section_three {
      width: 100%;
      height: auto;
      position: absolute;
      background-color: rgb(21, 118, 187);
      top: 0;
    }
  }
}
