.__ourBrand{
    width: 100%;
    height: auto;
    padding: 50px 0px;
    .__ourBrand_head{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        text-align: center;
        span{
            color: black;
        }
        p{
            padding-top:20px 100px 50px  100px;
        }
        .__ourBrand_in{
            padding: 0px 200px;
        }
        @media screen and (max-width:992px) {
             .__ourBrand_in{
            padding: 0px 0px;
        }
        }

    }
    .__ourBrand_section{
        align-items: center;
        justify-content: center;
        padding: 25px 40px;
        width: 100%;
        height: auto;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr;
       .__ourBrand_section_text{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       }
       @media screen and (max-width:1000px) {
        .__ourBrand_section_text{
            text-align: center;
        }
        
       }
        .__ourBrand_section_img{
            img{
                width: 100%;
            }
        }
        
    }
     @media screen and (max-width:1000px) {
            .__ourBrand_section{
                grid-template-columns: 1fr;

            }
            .I_a{
                order: 1;
            }
            .I_b{
                order: 2;
            }
            .I_c{
                order: 4;
            }
            .I_d{
                order: 3;
            }
            
        }
}