.laronda_con {
  width: 100vw;
  height: auto;
  .__factory__wrap_l {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 50px;
    padding: 50px 100px;
    padding-bottom: 100px !important;
    .__factory_banner_l {
      width: 100%;
      height: auto;
      overflow: hidden;

      img {
        width: 100% !important;
      }
      
    }
    .__factory__head_l {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: end;
        justify-content: end;
        .__factory__logo {
          padding: 0px 40px;
          width: 100%;
          img {
            width: 200px;
          }
        }
      }
  }
  @media screen and (max-width:1000px){
    .__factory__wrap_l{
          grid-template-columns: 1fr !important;
           padding: 0px;
            text-align: center;
        align-items: center;
        justify-content: center;
    }
     .__factory_banner_l {
      height: 40vh !important;
     }
     .__factory__text{
      padding: 0px 40px;
     }
    
  }

 
}
