.__retails_con {
  width: 100%;
  height: auto;
  text-align: center !important;

  .__re_head {
    width: 100% !important;
    width: 100vw !important;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;

    h1 {
      font-size: 50px !important;
      color: white !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
    }
  }
  .__re_wrap {
    padding: 100px 40px;
    width: 100%;
    text-align: left;
    grid-gap: 50px;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    .__re_wrap {
      grid-gap: 10px !important;
      text-align: center;
      grid-template-columns: 1fr !important;
      padding: 40px !important;
    }
  }

  .__re_logos {
    padding: 50px 40px 100px 40px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    .__re_logo_icon {
      border-radius: 10px;
      padding: 20px;
      width: 100%;
      height: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      img {
        padding-bottom: 50px;
        width: 150px;
      }
    }
  }
  .__re_form_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    .__re_form {
      text-align: left;
      .__exp__form {
        align-items: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 30px;

        input,
        textarea {
          outline: none;
          border-radius: 5px;
          width: 100%;
          padding: 5px 10px;
          margin-bottom: 20px;
          border: none;
          background-color: rgb(244, 244, 244);

          .placeholder {
            font-size: 12px;
            color: rgb(238, 238, 238) !important;
          }
        }
        input::placeholder {
          color: rgb(160, 160, 160);
          font-size: 14px;
        }
        .con_input {
          border-radius: 5px;
          width: 100%;
          padding: 5px 10px;
          margin-bottom: 20px;
          border: none;
          background-color: rgb(244, 244, 244);
          .__country__select {
            width: 100%;
            background: none;
            border: none;
            outline: none;
          }
        }

        .input_con {
          width: 100%;
        }
        .__exp__form {
          width: 100%;
          background-color: white !important ;
          padding: 30px;
          form {
            width: 100%;
            height: auto;

            .from_sec {
              gap: 30px;
              padding: 10px 0px;
              .from_sec_in {
                display: flex;
                gap: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .__re_logos {
      grid-template-columns: 1fr;
      padding: 10px 40px !important;
    }
    img {
      width: 50px;
    }
  }
  @media screen and (max-width: 900px) {
    .__re_form_wrap {
      grid-template-columns: 1fr;
    }
  }
  .__re_content {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    span {
      color: black;
    }

    .__re_con_in_wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
    @media screen and (max-width: 900px) {
      .__re_con_in_wrap {
        grid-template-columns: 1fr;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .__re_content {
      padding: 40px;
    }
  }
}
