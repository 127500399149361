.notions {
  width: 100vw;
  height: auto;
  .__factory_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  padding: 50px 100px;
    .__factory_banner {
      width: 100%;
      height: 60vh;
      overflow: hidden;

      img {
        width: 100% !important;
      }
      
    }
    .__factory__head {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: end;
        justify-content: end;
        .__factory__logo {
           padding: 0px 40px;
          width: 100%;
          img {
            width: 200px;
          }
        }
      }
      
  }
  @media screen and (max-width:1000px){
     .__factory_wrap{
         grid-template-columns: 1fr !important;
         padding: 0px !important;
          text-align: center;
        align-items: center;
        justify-content: center;
     }
     .__factory_banner {
      height: 40vh !important;
     }
     .__factory__text{

       
      padding: 0px 40px 100px 40px;
     }
    
  }
  
}