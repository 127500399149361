.confectionery_co {
  width: 100vw;
  height: auto;
  .__con_head {
    text-align: center;
    padding: 40px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 50px !important;
    }
  }
  .__con_main_cat_con {
    padding: 10px 40px 100px 40px !important;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important  ;
    grid-gap: 60px;
    justify-content: start !important;
    align-items: self-start !important;
    .__con_cat_card {
      overflow: hidden;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      -webkit-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      display: flex;
      flex-direction: column;
      width: 100%;

      .__con__cat_text {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        border-radius: 0px 0px 8px 8px;
        h2 {
          padding-top: 10px;
          font-size: 20px !important;
          color: black;
          font-weight: 300 !important;
          transition: 0.2s ease-in;
        }
      }

      .__con_cat_img {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: auto;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .__con_cat_card:hover {
      filter: opacity(5);
      -webkit-transform: translateY(-1px);
      transform: translateY(-10px);
 
    }
  }
  @media screen and (max-width: 992px) {
    .__con_main_cat_con {
      grid-template-columns: 1fr 1fr !important ;
      grid-gap: 20px;
    }
  }
  .conf_wrap {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    padding: 100px 40px;
    .conf_txt {
      text-align: center;
      padding: 30px 10px;
      .conf_text_head {
        padding-bottom: 50px !important ;
        width: 100%;
        text-align: center;
        h3 {
          font-weight: 600 !important;
          color: rgb(0, 0, 0);
        }
        .__text {
          font-size: 16px;
          line-height: 18px;
          overflow: hidden;
          transition: height 0.5 ease-in-out;
        }
      }
    }
    .conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .conf_wrap {
      grid-template-columns: 1fr;
    }
    .I_a{
      order: 1;
    }
    .I_b{
      order: 2;
    }
    .I_c{
      order: 4;
    }
    .I_d{
      order: 3;
    }
    .I_e{
      order: 5;
    }
    .I_f{
      order: 6;
    }
    .I_i{
      order: 8;
    }
    .I_j{
      order: 7;
    }
    .I_k{
      order: 9;
    }
    .I_l{
      order: 10;
    }
  }
}
