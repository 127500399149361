.confectionery_bki {
  width: 100vw;
  height: auto;
  .__con_head {
    padding: 40px;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .__con_main_cat_bk {
    padding: 0px 40px;
    width: 100%;
    height: auto;
    align-items: center;
    display: grid;
    grid-gap: 20px !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .__con_cat_card_bk {
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
      -webkit-transition: all 250ms ease-out !important;
      transition: all 250ms ease-out !important;
      display: flex;
      flex-direction: column;
      width: 100%;

      .__con__cat_text {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: auto;
        object-fit: contain;
        overflow: hidden;
        border-radius: 0px 0px 8px 8px;
        h2 {
          padding-top: 10px;
          line-height: 1.2 !important;
          font-size: 20px !important;
          color: black;
          font-weight: 300 !important;
          transition: 0.2s ease-in;
        }
      }

      .__con_cat_img {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .__con_cat_card_bk:hover {
      filter: opacity(5);
      -webkit-transform: translateY(-1px);
      transform: translateY(-10px);
    }
  }
  @media screen and (max-width: 992px) {
    .__con_main_cat_bk {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }

  .conf_wrap {
    width: 100%;
    height: auto;
    display: grid;
    grid-gap: 100px;
    grid-template-columns: 1fr 1fr;
    padding: 100px 40px;
    .conf_txt {
      text-align: center !important;
      .conf_text_head {
        padding-bottom: 15px;
        h3 {
          font-weight: 600 !important;
          color: rgb(0, 0, 0);
        }
      }
    }
    .conf_img {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .conf_wrap {
      grid-template-columns: 1fr;
    }
    .I_a{
      order: 1;
    }
    .I_b{
      order: 2;
    }
    .I_c{
      order: 4;
    }
    .I_d{
      order: 3;
    }
    .I_e{
      order: 5;
    }
    .I_f{
      order: 6;
    }
    .I_i{
      order: 8;
    }
    .I_j{
      order: 7;
    }
  }
}
